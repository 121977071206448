.container-footer {
  display: flex;
  justify-content: center;
}
footer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  max-width: 1000px;
}
.footer-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.footer-section > p {
  align-self: center;
  text-align: center;
  padding: 5px 15px;
  width: 95%;
  max-width: 1000px;
  font-size: 18px;
}
.footer-title {
  color: #f5b721;
  border-bottom: 1px solid #f5b721;
  width: 90%;
  text-align: center;
  margin: 0 auto;
}
.social-media-footer {
  display: flex;
  justify-content: center;
  gap: 50px;
}
.footer-link img:hover {
  transform: scale(1.2);
  transition: all 0.5s;
}
.footer-link img {
  max-width: 32px;
  max-height: 32px;
}
.copy-footer {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.copy-footer > span {
  display: flex;
  gap: 10px;
  margin: 0 auto;
  padding-bottom: 50px;
}
.copy-footer > span > p {
  color: #e6af20;
  font-style: italic;
}

#portfolio {
  text-decoration: none;
  color: white;
}

#portfolio:hover {
  color: red;
}
