.body-loading{
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: linear-gradient(90deg, rgb(21, 21, 21) 0%, #302f2f 50%, rgb(21, 21, 21) 100%);
    z-index: 100000;
}
.body-loaded{
   display: none;
   opacity: 0;
}
.img-loaded{
    visibility: hidden;
    height: 0px;
}
#animation{
    height: 100vh;
    width: 100%;
    margin: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0px;
    position: relative;
}

.parallax-text{
    position: absolute;
    font-size: 25vw;
}

.parallax-text::after{
    content: attr(text);
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    color: transparent;
    -webkit-text-stroke: 0.1vw rgb(0, 0, 0);
}

.loader-img{
    width: 35vh;
    animation: pizzaAnimation 1.75s cubic-bezier(0.25, 1, 0.5, 1) infinite alternate;
    position: absolute;
    top: 35%;
}

@keyframes pizzaAnimation{
    0%, 10%{
        transform: translate(100vw, 0) rotate(0deg);
    }
    90%, 100%{
        transform: translate(3vw, -5vw) rotate(-360deg);
    }
}


@media (min-width: 1024px) {
    .parallax-text{
        font-size: 15vw;
    }
    .loader-img{ 
        width: 45vh;
    }
    @keyframes pizzaAnimation{
        0%, 10%{
            transform: translate(100vw, 0) rotate(0deg);
        }
        90%, 100%{
            transform: translate(0vw, -5vw) rotate(-360deg);
        }
    }
}