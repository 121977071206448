.container-options{
    margin-top: 50px;
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    justify-content: space-around;
}
.options-item{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
}
.options-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(75%);
}
.options-circle{
    width: 200px;
    height: 200px;
    border: 2px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    padding: 20px 50px 70px 20px;
    font-size: 16px;
    font-weight: bold;
    bottom: -80px;
    right: -40px;
    opacity: 1;
}

@media (min-width: 769px) {
    .container-options{
        display: none;
    }
}
