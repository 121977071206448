input:focus,
textarea:focus {
  outline: none;
}

.section-register {
  min-height: 40vh;
  width: 100%;
  margin: auto;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  gap: 50px;
}

.titulo-register {
  font-size: 32px;
}

.top-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 900px;
}
.top-form > p {
  max-width: 700px;
}
.form-register {
  width: 95%;
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-register-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  margin: 0;
  align-self: center;
}

.form-register-input > input {
  height: 45px;
  padding-left: 5px;
  width: 100%;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.299);
  opacity: 0.7;
}

.button-card {
  width: 100px;
  font-size: 16px;
  font-weight: 550;
}
fieldset.form-register-input {
  width: 100%;
  border-color: #f5b721;
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding: 20px 5px;
}
fieldset.form-register-input > legend {
  font-size: 24px;
  padding: 5px;
  line-height: 16px;
}
fieldset.form-register-input > legend > small {
  font-size: 11px;
}

.form-images-container {
  width: 100%;
  height: 100%;
  min-height: 150px;
  max-width: 700px;
  display: flex;
  justify-content: space-around;
}
.form-img-div {
  width: 60%;
  min-height: 100%;
  border: 1px solid #f5b721;
  border-radius: 10px;
  position: relative;
  object-fit: cover;
  padding: 1px;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.image-grid-item {
  flex: 1 1 calc(33.333% - 10px);
  max-width: calc(33.333% - 10px);
  min-width: 100px;
}

/* MENUS PIZZA ASADO ETC */
.combo-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
  gap: 15px;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  height: auto;
  max-height: 50px;
}
/* Class to apply when a section is visible */
.combo-section-visible {
  max-height: 1200px;
  transition: max-height 0.5s ease-in-out;
}
.combo-header {
  color: #f5b721;
  font-size: 24px;
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
  cursor: pointer;
  width: 100%;
}
.combo-section > div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  text-align: left;
  width: 100%;
  position: relative;
}

/* TITULO Y PRECIO */

.option-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.option-container > label {
  width: 80%;
}
.option-container > p {
  width: 17%;
  text-align: center;
}

/* ITEMS */

.menu-food-list {
  text-align: left;
  margin-left: 45px;
  font-size: 14px;
}

/* CHECKBOX */

.checkbox-style > input {
  display: none;
}
.checkbox-style > span {
  display: inline-block;
  position: relative;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 20%;
  transition: all 0.5s;
  border: 1px solid #f5b721;
}
.checkbox-style {
  font-size: 15px;
  cursor: pointer;
}
.checkbox-style input:checked ~ span {
  box-shadow: 0px 0px 10px 0px #f5b721;
}
.checkbox-style > span:after {
  content: '';
  position: absolute;
  border: solid white;
  border-width: 0 2px 2px 0px;
  transform: rotate(45deg);
  width: 5px;
  height: 10px;
  left: 5px;
  top: 2px;
  opacity: 0;
  transition: 0.1s ease;
}
.checkbox-style input:checked ~ span:after {
  opacity: 1;
}

/* SOLAPA AMARILLA */

.yellow-form {
  width: calc(100vw - (100vw - 100%));
  height: 70px;
  display: block;
  position: fixed;
  bottom: 0px;
  color: black;
  background-color: #f5b721;
  border-radius: 20px 20px 0px 0px;
  transition: all 0.5s;
  z-index: 100;
}
.yellow-form.open {
  height: calc(70vh - (100vh - 100%));
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 5px 30px 5px;
  overflow-y: auto;
}
.yellow-form.open::-webkit-scrollbar {
  width: 5px;
  border-radius: 20px;
}
.yellow-form.open::-webkit-scrollbar-track {
  background-color: transparent;
}
.yellow-form.open::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #585050b5;
}
.yellow-form.open > .yellow-form-small {
  height: 5%;
  min-height: 50px;
  width: 100%;
  position: relative;
  padding: 0;
}
.yellow-form > .yellow-form-big {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title-yellow-form {
  width: 100%;
  font-size: 28px;
  text-align: left;
  align-self: center;
  margin-bottom: 15px;
}

.yellow-form-big > p {
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.form-register-input.quantity {
  margin-bottom: 20px;
}
.form-register-input.quantity > label {
  width: 100%;
  text-align: center;
}
.times-event {
  display: flex;
  justify-content: space-around;
}
.times-event > .form-register-input {
  width: 40%;
}
.date-event {
  background-color: #fff;
  box-sizing: border-box;
}
.time-label {
  margin-top: 10px;
  font-weight: 700;
  padding-left: 5px;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container > input {
  width: 100%;
  height: 45px;
  padding-left: 5px;
  border-radius: 5px;
  border: none;
  opacity: 0.7;
  margin: 5px;
}
.react-datepicker__input-container > span {
  display: none;
}
.react-datepicker__close-icon::after {
  background-color: #be1615;
}
.form-includes {
  width: 90%;
  max-width: 300px;
  align-self: center;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
  padding-bottom: 2px;
  margin-bottom: 5px;
}
.form-includes > h4 {
  align-self: flex-start;
  align-self: left;
}
.form-includes-list {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-self: center;
}
.includes-opt {
  width: 100%;
  padding: 2px;
  display: flex;
  justify-content: left;
}
.includes-opt > p {
  font-size: 13px;
  text-align: left;
  display: list-item;
  list-style: circle;
}
.includes-opt > small {
  width: 10%;
  font-size: 12px;
}

/* BOTON SOLAPA AMARILLA */

.button-open-form {
  width: 180px;
  height: 40px;
  color: white;
  border-radius: 5px;
  border: #be1615;
  background-color: #be1615;
  cursor: pointer;
  display: grid;
  place-items: center;
}
.button-close-form {
  position: absolute;
  color: black;
  top: 15px;
  right: 15px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 800;
}
.yellow-form-small {
  display: grid;
  place-items: center;
  padding: 20px 0px;
}

/* BOTONES formulario */

.event-form-button {
  cursor: pointer;
  width: 180px;
  height: 40px;
  color: white;
  border-radius: 5px;
  border: #be1615;
  background-color: #be1615;
  display: grid;
  place-items: center;
  align-self: center;
  margin: 10px;
}

.button-cancel {
  cursor: pointer;
  color: black;
  margin: 5px;
  text-decoration: underline;
  font-weight: 700;
  font-size: 16px;
  background-color: transparent;
  border: thin;
}

.button-toggle {
  display: inline-block;
  width: 50px;
  height: 20px;
  background-color: #fff;
  border-radius: 30px;
  cursor: pointer;
  margin: 10px;
}

#toggle {
  display: none;
}

.move {
  display: block;
  font-size: 10px;
  position: relative;
}

.move::after {
  content: 'NO';
  width: 25px;
  height: 25px;
  background-color: #be1615;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: -5px;
  left: 0;
  display: grid;
  place-content: center;
  line-height: 0;
  transition: background-color 0.25s, transform 0.25s ease-in;
}

#toggle:checked + .move::after {
  content: 'SI';
  background-color: #f5b721;
  transform: translateX(25px) rotate(360deg);
}

@media (min-width: 769px) {
  .yellow-form {
    width: 70%;
    max-width: 800px;
  }
  .form-img-div {
    width: 208px;
    height: 174px;
  }
  .combo-section > div {
    align-items: center;
  }
  .combo-section > ul {
    font-size: 16px;
  }
  .option-container {
    font-size: 20px;
  }
}
