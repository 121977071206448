.container {
    text-align: center;
    align-items: center;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    gap: 25px;
    flex-wrap: wrap;
}
.container-menu{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
.container-products{
    width: 45%;
    max-width: 300px;
}
.titulo-pizzas{
    font-size: 28px;
}

.container-products .card {
    height: 350px;
    width: 100%;
    background-color: #212121;
    border-radius: 1rem;
    border: #212121 0.2rem solid;
    transition: all 0.4s ease-in;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.737);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.product-title {
    font-size: 18px;
}
.product-img {
    width: 100%;
    height: 100%;
    min-height: 130px;
    object-fit: cover;
    border-radius: 1rem; 
}
.product-description{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50%;
}

.product-ingredients {
    font-size: 10px; 
}
.product-price{
    font-size: 18px;
    color: #f5b721;
}
.container-products .product {
        text-align: center;
        justify-content: start;
        padding: 10px; 
}
.button-card {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #18181a;
    display: inline-block;
    font-size: 12px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    background: #f5b721;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation; 
    border-radius: 10px;
}
.button-card > a{
    color: rgb(62, 62, 62);
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
}
.button-card-txt.adding{
    animation: added 1s ease-in-out forwards;
}
.quantity-container{
    display: flex;
    justify-content: center;
    gap: 10px;
    z-index: 50;
}
.input-quantity{
    font-size: 18px;
}
.button-quantity{
    border: 0px;
    color: white;
    background-color: transparent;
    cursor: pointer;
    font-size: 18px;
}
.slider-text-offer{
    background-color: #be1615;
    width: 100%;
    text-align: center;
    max-width: 600px;
    padding: 5px;
    border-radius: 10px;
}

@keyframes added {
    0% {
      opacity: 0;
      transform: translateY(-120px);
    }
    50% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(120px);
    }
  }

@media (min-width: 769px) {
    .titulo-pizzas{
        font-size: 48px;
    }

    .product-title{
        font-size: 24px;
    }

    .product-ingredients{
        font-size: 10px;
    }

    .container-products .card:hover {
        transform: translateY(-0.5rem);
        border: #be1615 2px solid;
    }
}
